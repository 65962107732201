.home-container {
  background-color: rgb(255, 255, 255);
  padding: 0 10vw;
  height: 100%;
}

.home-form {
  display: flex;
}
.home-input {
  margin-right: 1rem;
  width: 15rem;
}
.navbar {
  display: flex;
  display: none;
}

.nav-item {
  padding: 1rem;
  font-weight: bold;
  color: black;
}

.home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15vh;
  flex: 1;
}
.home-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;;
  margin: 0 auto;
}
.home-title h1 {
  font-size: 4rem;
}

.phone {
  display: flex;
  justify-content: center;
  flex: .5;
  margin : 3rem;
}