@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-container {
  background-color: rgb(255, 255, 255);
  padding: 0 10vw;
  height: 100%;
}

.home-form {
  display: -webkit-flex;
  display: flex;
}
.home-input {
  margin-right: 1rem;
  width: 15rem;
}
.navbar {
  display: -webkit-flex;
  display: flex;
  display: none;
}

.nav-item {
  padding: 1rem;
  font-weight: bold;
  color: black;
}

.home-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 15vh;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.home-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;;
  margin: 0 auto;
}
.home-title h1 {
  font-size: 4rem;
}

.phone {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: .5 1;
          flex: .5 1;
  margin : 3rem;
}
html {
  background-color: #f3f3f3;
}
.container {
  background-color: #ebebeb;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
}
.card {
  text-align: left;
  background: #fff;
  height: calc(1vh * 85);
  height: calc(var(--vh, 1vh) * 85);
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.card-title {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.card-subtitle {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.toggle-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem;
}

.title {
  font-family: 'Roboto', sans-serif;
  color: #383838;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0 0.75rem 0 0.75rem;
  text-align: center;
}
.app-title {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.subtitle {
  font-family: 'Roboto', sans-serif;
  color: #383838;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0.5rem 0.75rem 0 0.75rem;
  text-align: center;
}

form .div {
  display: none;
}

.card form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.card form div {
  margin: 2px 0;
}

.pill-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: auto;
  max-width: 300px;
  padding: 30px 0 10px 0;
}

.pill {
  height: 20px;
  width: 70px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
}

.pill.active {
  background-color: #3d8af7;
}

.react-swipeable-view-container div {
  overflow: unset !important;
}

.card-title {
  font-family: 'Roboto', sans-serif;
  color: #383838;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  -webkit-flex: .5 1;
          flex: .5 1;
  margin-top: 2rem;
}

.content {
  text-align: center;
  -webkit-flex: 3 1;
          flex: 3 1;
}

.card-initial-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 90%;
  margin: 0 2rem;
}

.emoji {
  font-size: 6rem;
}

@media screen and (max-width: 480px) {
  .emoji {
    font-size: 4rem;
  }
}

.next-button {
  background-color: #3d8af7;
  position: relative;
  bottom: 0px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  padding: 15px 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.checkbox-item {
  position: relative;
  margin: 0 2rem 1rem 2rem;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.5rem;
}

.checkbox-item.checked {
  background-color: #cfe2fe;
}

.checkbox-item .checkbox {
  padding: 0.5rem 1rem;
}

.checkbox-item .checkbox svg {
  width: 1.5em;
  height: 1.5em;
}

@media screen and (max-height: 640px) {
  .title {
    font-size: 2rem;
  }

  .emoji {
    font-size: 5rem;
  }
}

.confirmation-message {
  margin-top: 5rem;
  width: 80%;
}

h1,
h2 {
  font-family: 'Roboto', sans-serif;
  color: #383838;
}

.hidden {
  display: none;
}

.confirmation-container {
  margin-top: 2rem;
}

.logo {
  width: 50%;
  margin: auto;
}

.google-logo {
  display: -webkit-flex;
  display: flex;
  width: 250px;
  -webkit-align-self: center;
          align-self: center;
  margin-bottom: 2rem;
}

.google-logo:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.google-logo:hover {
  cursor: pointer;
}

.google-logo img {
  padding: 0.5rem;
  border: rgba(0, 0, 0, 0.2) solid 0.5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 2px 2px 5px #b3b3b3;
}

.google-logo div {
  background-color: rgb(61, 138, 247);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #ffffff;
  box-shadow: 2px 2px 5px #b3b3b3;
}
.google-logo img {
  padding: 0.5rem;
  border: rgba(0, 0, 0, 0.2) solid 0.5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 2px 2px 5px #b3b3b3;
}

.google-logo div {
  background-color: rgb(61, 138, 247);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #ffffff;
  box-shadow: 2px 2px 5px #b3b3b3;
}

.dashboard-card {
  width: 300px;
  margin: 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.card-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.cards-container {
  display: -webkit-flex;
  display: flex;
  padding: 0.5rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.MuiChip-root {
  margin: 0.25rem;
}

.card-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.total-container {
  width: 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 8rem;
  margin: .5rem;
}

.card-count {
  font-size: 3rem;
}

.info-cards {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 2rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 800px;
  margin: auto;
}

.drawer-menu .MuiDrawer-paper {
  width: 15rem;
}

.MuiPaper-root.MuiPaper-rounded {
  border-radius: 0.5rem;
}


.main-container .date-picker {
  margin-top: 4rem;
}

@media screen and (min-width: 600px) {
  .MuiPaper-root .MuiToolbar-regular {
    margin: auto;
    padding-left: 20rem;
  }
  #root header {
    display: none;
  }
  .main-container {
    margin-left: 15rem;
    margin-top: 4rem;
  }
  .main-container .date-picker {
    margin-top: 16px;
  }
  .locations-container {
    padding-top: 5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .MuiToolbar-root .menuButton {
    display: none;
  }
}

.menu-top {
  height: 4rem;
}

.chart-container {
  margin: 3rem;
  padding: 2rem;
  height: 60vh;
}

.fab {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
}
.add-location div {
  width: 100%;
}
.add-location button {
  display: block;
  margin-top: 1rem;
}
